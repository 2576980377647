import { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  styled,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useViewer from ':src/hooks/useViewer';
import Logo from '../Logo';
import MoLink from '../MoLink';
import LogoLetterM from '../LogoLetterM';
import { HeaderMenuProps, HeaderMenuMobileProps } from './HeaderMenu';

const HeaderMenu = dynamic<HeaderMenuProps>(() =>
  import('./HeaderMenu').then((mod) => mod.HeaderMenu),
);
const HeaderMenuMobile = dynamic<HeaderMenuMobileProps>(() =>
  import('./HeaderMenu').then((mod) => mod.HeaderMenuMobile),
);

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  flexShrink: 0,
}));

export default function Header() {
  const theme = useTheme();
  const { viewer } = useViewer();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileClose = () => {
    setIsMobileMenuOpen(false);
  };

  const isLoggedIn = viewer?.scope === 'user';

  return (
    <AppBar sx={{ py: { xs: 1, sm: 2 } }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          gap: theme.spacing(4),
        }}
      >
        <Link href="/">
          <Box display={{ xs: 'none', sm: 'block' }}>
            <Logo />
          </Box>
          <Box display={{ sm: 'none' }}>
            <LogoLetterM />
          </Box>
        </Link>

        <Box display={{ xs: 'none', sm: 'block' }} flexGrow={1}>
          <MoLink href="/courses" inheritColor>
            Browse directory
          </MoLink>
        </Box>

        {isLoggedIn ? (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ display: { sm: 'none' } }}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>

            <ActionsWrapper sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <MoLink href="/profile/courses" inheritColor>
                My courses
              </MoLink>
              <Button variant="text" onClick={handleMenu} color="inherit" sx={{ p: 1 }}>
                <Box display="flex" alignItems="center" fontWeight={600}>
                  <Avatar sx={{ mr: 2, width: 40, height: 40 }}>
                    {viewer.avatarPath && (
                      <Image
                        src={viewer.avatarPath}
                        layout="fill"
                        sizes="40px"
                        alt="My profile image"
                        loader={({ src, width }) => {
                          return `${process.env.NEXT_PUBLIC_IMGIX_HOSTNAME_USERS}${src}?w=${width}&auto=format`;
                        }}
                      />
                    )}
                  </Avatar>
                  {viewer.firstName} {viewer.lastName}
                </Box>
              </Button>
            </ActionsWrapper>
            <HeaderMenu anchorEl={anchorEl} handleClose={handleClose} />
          </>
        ) : (
          <ActionsWrapper>
            <Button
              variant="outlined"
              color="neutral"
              href={`/api/auth/login?returnTo=${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`}
            >
              Login
            </Button>

            <Link href="/signup" passHref legacyBehavior>
              <Button component="a">Sign up</Button>
            </Link>
          </ActionsWrapper>
        )}
      </Toolbar>
      {isLoggedIn && isMobile && (
        <HeaderMenuMobile isOpen={isMobileMenuOpen} handleClose={handleMobileClose} />
      )}
    </AppBar>
  );
}
