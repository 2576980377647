import React, { useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { subscribeToHubspotNewsletterMutation } from ':src/apollo/global/mutations';
import {
  SubscribeToHubspotNewsletterMutation,
  SubscribeToHubspotNewsletterMutationVariables,
} from ':src/apollo/global/__generated__/SubscribeToHubspotNewsletterMutation';

export default function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [subscribeToHubspotNewsletter, { loading }] = useMutation<
    SubscribeToHubspotNewsletterMutation,
    SubscribeToHubspotNewsletterMutationVariables
  >(subscribeToHubspotNewsletterMutation);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await subscribeToHubspotNewsletter({
      variables: {
        input: {
          email,
          firstName,
          hubspotListId: Number(process.env.NEXT_PUBLIC_HUBSPOT_MOTIVO_LEARNING_LIST_ID),
        },
      },
    });
    setIsSubmitted(true);
  };

  if (isSubmitted) {
    return (
      <Typography variant="body3">
        Welcome to the community! Look out for some resources from us soon.
      </Typography>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h3" mb={2}>
        Join our growing community of mental health professionals.
      </Typography>
      <Box mb={2}>
        <TextField
          variant="outlined"
          label="First name"
          name="firstName"
          fullWidth
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <TextField
          variant="outlined"
          label="Email address"
          name="email"
          type="email"
          fullWidth
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Button fullWidth type="submit" disabled={loading}>
        Join the list
      </Button>
    </form>
  );
}
