import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { NormalizedCacheObject } from '@apollo/client/cache';
import * as Sentry from '@sentry/nextjs';
import { getAccessToken } from '@auth0/nextjs-auth0';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';
import { GetServerSidePropsContext } from 'next';
import { useMemo } from 'react';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const PERSISTED_QUERIES_MANIFEST_NAME = 'persisted-query-manifest';
const persistedQueryLink = ['staging', 'production'].includes(process.env.NODE_ENV)
  ? createPersistedQueryLink(
      generatePersistedQueryIdsFromManifest({
        loadManifest: () =>
          import(
            // @ts-ignore
            `../../${PERSISTED_QUERIES_MANIFEST_NAME}.json`
          ),
      }),
    )
  : new ApolloLink((operation, forward) => forward(operation));

const authMiddleware = ({ req, res }: GetServerSidePropsContext) =>
  setContext(async () => {
    let token;

    try {
      const { accessToken } = await getAccessToken(req, res);
      if (accessToken && process.env.NODE_ENV === 'development' && process.env.LOG_ACCESS_TOKEN) {
        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify(
            {
              Authorization: `Bearer ${accessToken}`,
            },
            null,
            2,
          ),
        );
      }
      token = accessToken;
    } catch {
      token = null;
    }
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  if (networkError) {
    Sentry.captureException(`[Network error]: ${networkError}`);
  }
});

function createApolloClient(ctx?: GetServerSidePropsContext) {
  let links;
  if (ctx) {
    links = [
      persistedQueryLink,
      authMiddleware(ctx),
      errorLink,
      createUploadLink({
        uri: `${process.env.API_URL}/api/graphql`,
      }),
    ];
  } else {
    links = [
      persistedQueryLink,
      errorLink,
      createUploadLink({
        uri: `${process.env.NEXT_PUBLIC_BASE_URL}/api/graphql`,
      }),
    ];
  }

  return new ApolloClient({
    // TODO: https://linear.app/motivo/issue/MOT-1737/investigate-apollolink-error
    // @ts-ignore
    link: from(links),
    cache: new InMemoryCache(), //{
    // typePolicies: {
    // Query: {
    // fields: {
    // courses: {
    // keyArgs: [
    // 'flagship',
    // 'courseTypeId',
    // 'topicId',
    // 'licenseCategoryId',
    // 'creditHours',
    // ],
    // merge(existing, incoming) {
    // if (!existing) return incoming;
    // return {
    // __typename: 'CoursesQueryResult',
    // result: [...existing.result, ...incoming.result],
    // total: incoming.total,
    // };
    // },
    // },
    // },
    // },
    // },
    // }),
    ssrMode: typeof window === 'undefined',
  });
}

export function initializeApollo(initialState = null, ctx?: GetServerSidePropsContext) {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  // @ts-ignore
  pageProps,
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

// @ts-ignore
export function useApollo(pageProps) {
  const state = pageProps ? pageProps[APOLLO_STATE_PROP_NAME] : null;
  const store = useMemo(() => initializeApollo(state), [state]);
  if (!pageProps) return null;
  return store;
}
