import { ReactNode } from 'react';
import { Box, Typography, Container, styled } from '@mui/material';
import MoLink from './MoLink';
import NewsletterSignup from './NewsletterSignup';
import Spacing from './Spacing';
import Logo from './Logo';
import SocialLinks from './SocialLinks';

const FooterWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(8)} 0`,
  display: 'flex',
  gap: theme.spacing(6),
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
}));

type FooterLinkProps = {
  href: string;
  children: ReactNode;
};

const FooterLink = ({ href, children }: FooterLinkProps) => (
  <MoLink href={href} display="block" fontWeight="500 !important" inheritColor>
    {children}
  </MoLink>
);

export default function Footer() {
  return (
    <Container>
      <FooterWrapper>
        <Box maxWidth={370}>
          <NewsletterSignup />
        </Box>
        <Box display="flex" gap={4}>
          <Box width={140}>
            <Spacing y={2}>
              <FooterLink href="/about-us">About us</FooterLink>
              <FooterLink href="/support">Support</FooterLink>
              <FooterLink href="https://motivohealth.com">Supervision</FooterLink>
              <FooterLink href="https://www.motivo.community">Community</FooterLink>
            </Spacing>
          </Box>
          <Spacing y={2}>
            <FooterLink href="/terms">Terms</FooterLink>
            <FooterLink href="/privacy">Privacy</FooterLink>
          </Spacing>
        </Box>
        <Box>
          <Box mb={2} textAlign={{ xs: 'center', md: 'left' }}>
            <Logo />
            <Typography mt={2}>© Motivo 2024</Typography>
          </Box>
          <SocialLinks
            gap={3}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            facebookURL="https://www.facebook.com/motivohealth"
            instagramURL="https://www.instagram.com/motivohealth"
            twitterURL="https://twitter.com/motivohealth"
            linkedinURL="https://www.linkedin.com/company/motivohealth"
          />
        </Box>
      </FooterWrapper>
    </Container>
  );
}
